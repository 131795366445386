import React, { createContext, useState } from "react"

const defaultValues = {
  isLoggedIn: false,
  userEmail: null,
  userLogin: () => {},
  clearUser: () => {},
  handlizeTitle: () => {},
}

export const AppContext = createContext(defaultValues)

export const AppProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(defaultValues.isLoggedIn)
  const [userEmail, setUserEmail] = useState(defaultValues.userEmail)

  const userLogin = email => {
    setUserEmail(email)
    setLoggedIn(true)
  }
  const clearUser = () => {
    setLoggedIn(false)
    setUserEmail(null)
  }

  const handlizeTitle = handle => {
    return handle
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/-$/, "")
      .replace(/^-/, "")
  }

  return (
    <AppContext.Provider
      value={{
        ...defaultValues,
        isLoggedIn,
        userEmail,
        userLogin,
        clearUser,
        handlizeTitle,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
